<template>
  <div class="fixed-page">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" />
      <div class="exhibitBox">
        <div class="exhibitTitle">
          <img src="@/assets/img/renyiOverview/jiantou.png" />
          <span class="title">人艺文创</span>
        </div>
        <div class="exhibitlist">
          <div
            class="exhibitItem"
            v-for="item in exhibitsList"
            :key="item.id"
            @click="dialogVisible = true"
          >
            <img :src="item.image[0]" alt="" @error="imgOnError($event)" />
            <div class="exhibitName">
              <div class="name">{{ item.title }}</div>
              <div class="author">
                <div class="authorName">{{ item.creatorName }}</div>
                <div class="buy">购买<i class="el-icon-right"></i></div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="query.pageNo"
            :page-size="16"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <PageFooter />
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      top="20vh"
      append-to-body
    >
      <div class="qr-dialog">
        <p>手机扫码进入</p>
        <p>北京人艺戏剧书店微信公众号，</p>
        <p>购买戏剧图书和戏剧文创。</p>
        <img
          src="@/assets/img/performance/store.png"
          alt=""
          @error="imgOnError($event)"
        />
        <p>扫描二维码进店</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getArticlePage } from "@/service/culture";
import slotImg from "@/assets/img/slotImg.jpg";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 20,
        isShow: 1,
      },
      total: 0,
      exhibitsList: [],
      dialogVisible: false,
    };
  },
  created() {
    this.getExhibitPage(this.query);
  },
  methods: {
    async getExhibitPage(params) {
      const res = await getArticlePage({
        condition: { title: "", level1: "culture", level2: "rywc", status: 1 },
        ...params,
      });
      this.exhibitsList = res.data.data || [];
      for (let i = 0; i < this.exhibitsList.length; i++) {
        this.exhibitsList[i].image = this.exhibitsList[i].image.split(",");
      }
      this.total = res.data.totalCount;
    },
    handleCurrentChange(val) {
      this.query.pageNo = val;
      this.getExhibitPage(this.query);
    },
    imgOnError(e) {
      let img = e.srcElement;
      img.src = slotImg;
      img.onerror = null; //防止闪图
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f5f6f8;
  padding-top: 1.0417vw;
  padding-bottom: 2.6042vw;
  .exhibitBox {
    width: 72.9167vw;
    margin: auto;
    padding: 2.6042vw;
    box-sizing: border-box;
    background-color: #fff;
    .exhibitTitle {
      img {
        width: 0.7292vw;
        height: 1.3542vw;
        margin-right: 0.625vw;
      }
      .title {
        font-size: 1.3542vw;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #212122;
      }
    }
    .exhibitlist {
      display: flex;
      flex-wrap: wrap;
      .exhibitItem {
        width: 15.3646vw;
        height: 26.0417vw;
        margin-top: 1.5625vw;
        margin-right: 1.5625vw;
        cursor: pointer;
        img {
          width: 15.3646vw;
          height: 21.6667vw;
        }
        .exhibitName {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 15.3646vw;
          height: 3.125vw;
          background: #f8f8fa;
          padding: 0 1.0417vw;
          box-sizing: border-box;
          .name {
            font-size: 0.8333vw;
            margin-bottom: 0.5208vw;
          }
          .author {
            display: flex;
            justify-content: space-between;
            .authorName {
              font-size: 0.625vw;
            }
            .buy {
              font-size: 0.625vw;
              i {
                margin-left: 0.2604vw;
              }
            }
          }
        }
      }
    }
  }
}
.block {
  margin-top: 3.125vw;
  //   margin-bottom: 52px;
}

img {
  object-fit: cover;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: center;
}
::v-deep .el-pager li {
  width: 1.6667vw;
  height: 1.6667vw;
  background: #ffffff;
  border-radius: 0.3125vw 0.3125vw 0.3125vw 0.3125vw;
  text-align: center;
  line-height: 1.6667vw;
  border: 0.0521vw solid rgba(0, 0, 0, 0.149);
  margin-right: 0.4167vw;
}
::v-deep .el-pager li:last-child {
  margin-right: 0;
}
::v-deep .el-pager .active {
  color: #f9680d;
  border: 0.0521vw solid #f9680d;
}
.qr-dialog {
  text-align: center;
  img {
    width: 12.5vw;
    height: 12.5vw;
    margin: auto;
  }
  p {
    margin: 0.5208vw 0;
  }
}
</style>
